<template>
  <v-container fluid>
    <v-overlay z-index="5" :value="overlay" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-card-text v-if="statusOperation != 'delete'">
        <v-row>
          <v-col>
            <validation-observer ref="observer1" tag="form">
              <v-form ref="form1">
                <v-row>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      name="Field"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-select
                        outlined
                        class="rounded-0"
                        :disabled="provideBool"
                        :menu-props="{ down: true, offsetY: true }"
                        hide-selected
                        hide-details="auto"
                        :error-messages="errors"
                        :items="['Multiple Choice', 'Text Answer']"
                        v-model="alternative"
                        @change="selectAlternative"
                        label="Choose..."
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <validation-observer ref="observer2" tag="form">
              <v-form ref="form1">
                <v-row v-if="showQuestion">
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Question"
                      rules="required"
                    >
                      <v-text-field
                        class="rounded-0"
                        autofocus
                        hide-details="auto"
                        outlined
                        label="Click To Enter Question Here"
                        :error-messages="errors"
                        v-model="question"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <template v-if="multiChoice">
                  <v-row
                    align="center"
                    v-for="(value, i) in multiValues"
                    :key="i"
                  >
                    <v-col cols="12" lg="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Value"
                        :rules="{
                          required: true,
                          alpha_spaces: true,
                          distinct: '@' + value.item,
                        }"
                        :vid="'name' + i"
                      >
                        <v-text-field
                          ref="option"
                          class="rounded-0"
                          hide-details="auto"
                          outlined
                          :label="`Option ${i + 1}`"
                          :error-messages="errors"
                          v-model="value.item"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="1">
                      <div class="text-center">
                        <v-btn
                          v-if="multiValues.length > 2"
                          dark
                          icon
                          large
                          color="red"
                          @click="spliceRow(i)"
                        >
                          <v-icon large dark> mdi-close-circle </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="1">
                      <v-btn
                        v-if="i + 1 === multiValues.length"
                        color="success"
                        icon
                      >
                        <v-icon @click="newRow" x-large>
                          mdi-plus-circle
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <v-col cols="1">
                    <v-switch
                      v-model="switch1"
                      inset
                      :label="`${switch1 ? 'Active' : 'Inactive'}`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row class="text-h6">
          <v-col cols="12" v-if="deleteSuccess == ''">
            <span>
              Do You Want To Delete Question
              <b> Number {{ questionItem.index }} </b>?
            </span>
          </v-col>

          <v-col v-else>
            Question Number {{ questionItem.index }} Is Deleted Successfully
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-footer app color="transparent">
          <template v-if="provideBool">
            <v-btn
              right
              absolute
              bottom
              color="primary"
              @click="updateQuestionBtn(questionItem.id)"
              v-if="statusOperation == 'edit'"
            >
              Save Changes
            </v-btn>
            <div v-else>
              <v-btn
                dark
                color="grey"
                right
                absolute
                bottom
                @click="closeQuestionBtn"
                v-if="deleteSuccess.length > 0"
              >
                Close
              </v-btn>
              <v-btn
                right
                absolute
                v-else
                bottom
                color="primary"
                @click="deleteQuestionBtn(questionItem.id)"
              >
                Delete Question
              </v-btn>
            </div>
          </template>

          <v-btn
            v-else
            right
            absolute
            bottom
            color="primary"
            @click="addQuestionBtn"
          >
            Save Question
          </v-btn>
        </v-footer>
      </v-card-actions>
    </v-card>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    computed,
    defineComponent,
    provide,
    reactive,
    toRefs,
    onMounted,
    getCurrentInstance,
  } from "vue";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { alpha_spaces, required, max } from "vee-validate/dist/rules";
  import { useActions } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  extend("max", {
    ...max,
    message: "{_field_} must be 255 characters only",
  });

  export default defineComponent({
    components: { ValidationProvider, ValidationObserver, Response },
    props: ["questionData", "statusType"],
    setup(props, context) {
      const vm = getCurrentInstance();
      const { saveQuestion, signOut, updateQuestion, deleteQuestion } =
        useActions([
          "saveQuestion",
          "signOut",
          "updateQuestion",
          "deleteQuestion",
        ]);

      const questionForm = reactive({
        choices: ["Multiple Choice", "Text Answer"],
        alternative: "",
        showQuestion: false,
        multiChoice: false,
        multiValues: [{ item: "" }, { item: "" }],
        observer1: null,
        observer2: null,
        observer3: null,
        option: null,
        valid: true,
        question: null,
        switch1: false,
        color: null,
        response: false,
        msgHeader: "",
        msgBody: "",
        overlay: false,
        msgIcon: "",
        form1: null,
        provideBool: false,
        deleteSuccess: "",
      });
      const {
        choices,
        showQuestion,
        multiChoice,
        multiValues,
        observer1,
        observer2,
        alternative,
        question,
        switch1,
        overlay,
        color,
        response,
        msgHeader,
        msgBody,
        msgIcon,
        form1,
        option,
        provideBool,
        deleteSuccess,
      } = toRefs(questionForm);

      extend("distinct", {
        validate(value) {
          return (
            multiValues.value.filter(
              (elem) => elem.item.toLowerCase() == value.toLowerCase()
            ).length <= 1
          );
        },
        message: "{_field_} can not be the same",
      });

      const selectAlternative = (item) => {
        showQuestion.value = true;
        switch (choices.value.indexOf(item)) {
          case 0:
            multiChoice.value = true;
            break;
          case 1:
            multiChoice.value = false;
            multiValues.value = [{ item: "" }, { item: "" }];
            break;
          default:
            break;
        }
      };

      const newRow = async () => {
        await observer2.value.validate().then((result) => {
          if (result) {
            multiValues.value.push({ item: "" });

            vm.proxy.$nextTick(() =>
              option.value[multiValues.value.length - 1].focus()
            );
          }
        });
      };

      const spliceRow = (i) => {
        multiValues.value.splice(i, 1);

        vm.proxy.$nextTick(() =>
          option.value[multiValues.value.length - 1].focus()
        );
      };

      const addQuestionBtn = async () => {
        await observer1.value.validate().then((result) => {
          if (result) {
            observer2.value.validate().then((result) => {
              if (result) {
                response.value = false;
                overlay.value = true;
                saveQuestion({
                  type: alternative.value,
                  question: question.value,
                  alternatives: multiChoice.value ? multiValues.value : null,
                  active: switch1.value,
                })
                  .then(() => {
                    msgHeader.value = "Success";
                    msgBody.value = "Saved successfully";
                    msgIcon.value = "mdi-check-circle";
                    color.value = "success";
                    response.value = true;
                  })
                  .catch((e) => {
                    response.value = true;
                    msgHeader.value = "Error";
                    msgIcon.value = "mdi-close-circle";
                    color.value = "error";
                    switch (e.response.status) {
                      case 403:
                      case 401:
                        msgBody.value = e.response.data.message;
                        break;
                      case 423:
                        msgBody.value = e.response.data.message;
                        signOut().then(() => {
                          vm.proxy.$router.replace({
                            name: "Login",
                          });
                        });
                        break;
                      default:
                        msgBody.value =
                          "Try Again Later or Call The System Administrator";
                        break;
                    }
                  })
                  .finally(() => {
                    form1.value.reset();
                    console.log(form1.value.reset(), observer1.value.reset());
                    observer1.value.reset();
                    observer2.value.reset();
                    overlay.value = false;
                    switch1.value = false;
                    alternative.value = null;
                  });
              }
            });
          }
        });
      };

      const updateQuestionBtn = async (id) => {
        await observer1.value.validate().then((result) => {
          if (result) {
            observer2.value.validate().then((result) => {
              if (result) {
                response.value = false;
                overlay.value = true;
                updateQuestion({
                  id: id,
                  type: alternative.value,
                  question: question.value,
                  alternatives: multiChoice.value ? multiValues.value : null,
                  active: switch1.value,
                })
                  .then(() => {
                    msgHeader.value = "Success";
                    msgBody.value = "Saved successfully";
                    msgIcon.value = "mdi-check-circle";
                    color.value = "success";
                    response.value = true;
                  })
                  .catch((e) => {
                    response.value = true;
                    msgHeader.value = "Error";
                    msgIcon.value = "mdi-close-circle";
                    color.value = "error";
                    switch (e.response.status) {
                      case 403:
                      case 401:
                        msgBody.value = e.response.data.message;
                        break;
                      case 404:
                        msgBody.value =
                          "Question Does Not Exists Or Already Deleted";
                        break;
                      case 423:
                        msgBody.value = e.response.data.message;
                        signOut().then(() => {
                          vm.proxy.$router.replace({
                            name: "Login",
                          });
                        });
                        break;
                      default:
                        msgBody.value =
                          "Try Again Later or Call The System Administrator";
                        break;
                    }
                  })
                  .finally(() => {
                    overlay.value = false;
                  });
              }
            });
          }
        });
      };

      const deleteQuestionBtn = (id) => {
        overlay.value = true;
        response.value = false;
        deleteQuestion(id)
          .then(() => {
            /* msgHeader.value = "Success";
                                      msgBody.value = "Saved successfully";
                                      msgIcon.value = "mdi-check-circle";
                                      color.value = "success";
                                      response.value = true; */
            deleteSuccess.value = "Question Is Deleted Successfully";
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 404:
                msgBody.value = "Question Does Not Exists Or Already Deleted";
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            //response.value = true;
            overlay.value = false;
          });
      };

      const closeQuestionBtn = () => {
        context.emit("deleteQuestion", true);
      };

      provide("color", color);

      const questionItem = computed(() => props.questionData);
      const statusOperation = computed(() => props.statusType);

      onMounted(() => {
        if (questionItem.value && statusOperation.value) {
          provideBool.value = true;
          selectAlternative(questionItem.value.type);
          alternative.value = questionItem.value.type;
          question.value = questionItem.value.question;

          multiValues.value = questionItem.value.alternatives;
          switch1.value = questionItem.value.active;
        }
      });

      return {
        ...toRefs(questionForm),
        selectAlternative,
        newRow,
        spliceRow,
        addQuestionBtn,
        statusOperation,
        questionItem,
        updateQuestionBtn,
        deleteQuestionBtn,
        closeQuestionBtn,
      };
    },
  });
</script>
